import React from 'react';
import { Container, Row } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';


const EmptyLayout = ({ children }) => {
  return (
    <Container className="d-flex flex-column justify-content-center align-items-center vh-100">
		 <Row className="justify-content-center g-2 w-100">
			
      {children}
          	<Outlet/>
          	
		  </Row>
    </Container>
  );
};

export default EmptyLayout;
