import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { MUZAIC_API_ROOT } from '../config';

const GoogleRedirectHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleGoogleLogin = async () => {
      const urlParams = new URLSearchParams(location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');

		// Parse the state JSON string to retrieve your custom data
		const stateData = JSON.parse(decodeURIComponent(state));

		// Now you can access your custom variables
		const keepMeLoggedIn = stateData.keepMeLoggedIn;

     	const canvaState=stateData.canvaState || false;

      if (code) {
        try {
          const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_googleLogin.php`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ code, keepMeLoggedIn }), // Pass keepMeLoggedIn to the backend
          });

          const data = await response.json();
          //const data = await response.text();
          //console.log(data);
          
          if (response.ok) {
            sessionStorage.setItem('muzaic_access_token', data.accessToken);
            if (keepMeLoggedIn && data.refreshToken) {
              localStorage.setItem('muzaic_refresh_token', data.refreshToken);
            }
        
            if (canvaState) navigate(`/purchase-only?state=${canvaState}`);
            else navigate('/dashboard');
          } else {
            navigate(`/login?state=${canvaState}&error=${encodeURIComponent(data.message || 'Login failed. Please try again.')}`);
          }
        } catch (error) {
        	
          navigate(`/login?state=${canvaState}&error=${encodeURIComponent('An error occurred during Google login. Please try again later.')}`);
        }
      } else {
        navigate(`/login?state=${canvaState}&error=${encodeURIComponent('No authorization code found in the URL.')}`);
      }
    };

    handleGoogleLogin();
  }, [location, navigate]);

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
      <Spinner animation="border" size="sm" />
    </div>
  );
};

export default GoogleRedirectHandler;
