import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import Stats from './pages/Stats';
import Help from './pages/Help';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import Purchase from './pages/Purchase';
import PurchaseOnly from './pages/PurchaseOnly';
import PurchaseResponse from './pages/PurchaseResponse';
import MyMusic from './pages/MyMusic';
import AuthLayout from './layouts/AuthLayout'; 
import MainLayout from './layouts/MainLayout';
import EmptyLayout from './layouts/EmptyLayout';
import ProtectedRoute from './components/ProtectedRoute';
import GoogleRedirectHandler from './components/GoogleRedirectHandler';


import Logout from './components/Logout';

import './App.css';

import { MUZAIC_ROOT } from './config';  // Import the constant
import { MUZAIC_API_ROOT } from './config';

function App() {
  return (
  	<HelmetProvider>
		<Router>
		  <Routes>
			{/* Routes without header and sidebar */}
			  <Route path="/" element={<ProtectedRoute><EmptyLayout /></ProtectedRoute>}>
            	<Route path="purchase-only" element={<Purchase simpleView={true}/>} />
            </Route>
            <Route path="/" element={<ProtectedRoute><AuthLayout /></ProtectedRoute>}>
            	<Route path="purchase-response" element={<PurchaseResponse />} />
            </Route>
			<Route element={<AuthLayout />}>
				<Route path="/login" element={<Login />} />
		
				<Route path="/signup" element={<SignUp />} />
				<Route path="/forgot-password" element={<ForgotPassword />} />
				<Route path="/password-reset" element={<PasswordReset />} />
          	</Route>
          	
			<Route path="/signup" element={<SignUp />} />
			<Route path="/logout" element={<Logout />} />
			<Route path="/oauth2-callback" element={<GoogleRedirectHandler />} />
			{/* Add other auth routes here */}
			
			{/* Routes with header and sidebar */}
			  <Route path="/" element={<ProtectedRoute><MainLayout /></ProtectedRoute>}>
            <Route index element={<Dashboard />} />
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="purchase" element={<Purchase simpleView={false}/>} />
            <Route path="mymusic" element={<MyMusic />} />
            <Route path="profile" element={<Profile />} />
            <Route path="stats" element={<Stats />} />
            <Route path="help" element={<Help />} />
          </Route>
	
			{/* Default route */}
			<Route path="*" element={<Navigate to="/dashboard" />} />
		  </Routes>
		</Router>
	</HelmetProvider>
  );
}

export default App;
