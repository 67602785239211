import React, { useEffect } from 'react';
import { Row, Col, Form, Button, Image, Alert, Spinner } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { Link,useLocation,useNavigate } from 'react-router-dom';
import { MUZAIC_ROOT, MUZAIC_API_ROOT } from '../config';
import GoogleLoginComponent from '../components/GoogleLoginComponent_2';

const Signup = () => {
  const [username, setUsername] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [confirmPassword, setConfirmPassword] = React.useState('');
  const [keepMeLoggedIn, setKeepMeLoggedIn] = React.useState(true);
  const [errors, setErrors] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [serverError, setServerError] = React.useState('');
  	const [canvaDetails, setCanvaDetails] = React.useState(false);

	const location = useLocation();
	const navigate= useNavigate();

	const validateEmail = (email) => {
		const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return re.test(String(email).toLowerCase());
	};


	useEffect(() => {
	  const fetchNonceValidation = async () => {
		const urlParams = new URLSearchParams(location.search);
		const error = urlParams.get('error');
		const canvaLogin = urlParams.get('canva_user_token');
		const nonce = urlParams.get('nonce');
		const state = urlParams.get('state'); // Capture the state parameter from the URL
		
		if (error) {
		  setServerError(error);
		  return;
		}
	
		if (canvaLogin && nonce) {
		  setCanvaDetails({
			'canva_user_token': canvaLogin,
			'state': state,
			'nonce': nonce
		  });
	
		  try {
			const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_canvaValidate.php?nonce=${nonce}`, {
			  method: 'GET',
			  credentials: 'include',
			});
	
			const data = await response.json();
			//if (response.ok) {
			if (1) {
			  console.log("Nonce validation successful:", data);
			  
			} else {
			  const errorMessage = data.message || 'Nonce validation failed';
			  // Redirect to Canva URL with error
			  //window.location.href = `https://www.canva.com/apps/configured?success=false&state=${state}&errors=11}`;
        
			}
		  } catch (error) {
			const errorMessage = 'An error occurred during nonce validation';
			// Redirect to Canva URL with error
			 //window.location.href = `https://www.canva.com/apps/configured?success=false&state=${state}&errors=12}`;
		  }
		  console.log("mockuped nonce");
		  
		}
	  };
	
	  fetchNonceValidation();
}, [location, navigate]);

	  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    setServerError('');
    setIsLoading(true);

    const newErrors = {};

    if (!username) {
      newErrors.username = 'Username is required';
    }
    if (!email) {
      newErrors.email = 'Email is required';
    } else if (!validateEmail(email)) {
      newErrors.email = 'Invalid email format';
    }
    
    if (!password) {
      newErrors.password = 'Password is required';
    }
    if (password !== confirmPassword) {
      newErrors.confirmPassword = 'Passwords do not match';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    try {
      const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_register.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, email, password, keepMeLoggedIn }),
      });

      const data = await response.json();
      if (response.ok) {
        // Store the tokens and redirect based on keepMeLoggedIn
        if (data.accessToken) {
          if (keepMeLoggedIn && data.refreshToken) {
            localStorage.setItem('muzaic_refresh_token', data.refreshToken);
          }
          sessionStorage.setItem('muzaic_access_token', data.accessToken);
          
          
          if(!canvaDetails.state) navigate ('/dashboard');
          else navigate(`/purchase-only?state=${canvaDetails.state}`);
        } else {
          setServerError('Unexpected response format. Please try again.');
        }
      } else {
        setServerError(data.message || 'Registration failed. Please try again.');
      }
    } catch (error) {
      setServerError('An error occurred. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Sign Up – Muzaic</title>
      </Helmet>

      <Row className={`justify-content-center ${canvaDetails.state ? 'mb-2 mt-n1':'g-2 mb-4'}`}>
        <Col md={5} xs={10} className="d-flex justify-content-center align-items-center py-3">
          <Image src={`${MUZAIC_ROOT}img/muzaic_logo.svg`} alt="Company Logo" className="logo_text" fluid />
        </Col>
      </Row>

      <Row className="g-2 p-2">
        <Col>
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="Username">
              <Form.Control
                type="text"
                className="form-control-lg bg-white font-small"
                placeholder="Enter name"
                onChange={(e) => setUsername(e.target.value)}
                isInvalid={!!errors.username}
              />
              <Form.Control.Feedback type="invalid">
                {errors.username}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="Email">
              <Form.Control
                type="email"
                className="form-control-lg bg-white font-small"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="Password">
              <Form.Control
                type="password"
                className="form-control-lg bg-white font-small"
                placeholder="Password"
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={!!errors.password}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group className="mb-3" controlId="ConfirmPassword">
              <Form.Control
                type="password"
                className="form-control-lg bg-white font-small"
                placeholder="Confirm password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                isInvalid={!!errors.confirmPassword}
              />
              <Form.Control.Feedback type="invalid">
                {errors.confirmPassword}
              </Form.Control.Feedback>
            </Form.Group>
			
			{ !canvaDetails.state ? 
            (<Form.Group controlId="keepMeLoggedIn" className="mt-3 ps-2">
              <Form.Check
                type="checkbox"
                label="Keep me logged in"
                checked={keepMeLoggedIn}
                onChange={(e) => setKeepMeLoggedIn(e.target.checked)}
              />
            </Form.Group>)
            : null
			}
            <Button variant="primary" type="submit" className="btn-lg w-100 mt-2 font-small" disabled={isLoading}>
              {isLoading ? <Spinner animation="border" size="sm" /> : 'Sign Up'}
            </Button>
			
			<GoogleLoginComponent setServerError={setServerError} keepMeLoggedIn={keepMeLoggedIn} canvaState={canvaDetails.state}/>
			
            {serverError && <Alert variant="danger" className="mt-3">{serverError}</Alert>}
          </Form>
        </Col>
      </Row>

      <Row className={`justify-content-center w-100 ${canvaDetails.state ? 'mt-2':'g-2 mt-4'}`}>
        <Col className="text-center">
          <span>Already have an account? <Link to={`/login${canvaDetails ? `?state=${canvaDetails.state}&canva_user_token=${canvaDetails.canva_user_token}&nonce=${canvaDetails.nonce}` : ''}`} 
           className="inline-link nav-link">Log in</Link></span>
        </Col>
      </Row>
    </>
  );
};

export default Signup;
