import React, { useState, useEffect } from 'react';
import { Form, Button, Alert, Table, Row, Col } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { MUZAIC_API_ROOT } from '../config';

const Profile = () => {
  const [profile, setProfile] = useState({ name: '', email: '', canvaId:'' });
  const [apiKeys, setApiKeys] = useState([]);
  const [loadingProfile, setLoadingProfile] = useState(true);
  const [loadingApiKeys, setLoadingApiKeys] = useState(true);
const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_userDetails.php`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }

        const data = await response.json();

        setProfile({ name: data.name, email: data.email, canvaId: data.canvaId });
        setLoadingProfile(false);
      } catch (error) {
        setError(error.message);
        setLoadingProfile(false);
      }
    };

    const fetchApiKeys = async () => {
      try {
        const response = await fetch(`${MUZAIC_API_ROOT}app_api/aa_getApiKeys.php`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch API keys');
        }

        const data = await response.json();
        setApiKeys(data);
        setLoadingApiKeys(false);
      } catch (error) {
        setError(error.message);
        setLoadingApiKeys(false);
      }
    };

    fetchProfile();
    fetchApiKeys();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await fetch(`${MUZAIC_API_ROOT}auth/ma_updateUserDetails.php`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${sessionStorage.getItem('muzaic_access_token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(profile),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      setSuccess('Profile updated successfully');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const requestSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key !== key) return null;
    if (sortConfig.direction === 'asc') return ' 🔼';
    return ' 🔽';
  };

  return (
    <>
      <Helmet>
        <title>Profile – Muzaic</title>
      </Helmet>
      <div>
      <h1>My Profile</h1>
        <p>This is your Muzaic account details.</p>
      	<div className="mt-5 mb-3">
        <h5>Personal Information</h5>
        {loadingProfile && <p>Loading...</p>}
        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}
        {!loadingProfile && (
          <Form onSubmit={handleSubmit} className="mb-3 font-small">
          	<Row>
          	<Col md={6}>
            <Form.Group controlId="name" className="mb-3">
              <Form.Label>Name</Form.Label>
              <Form.Control
                className="bg-white"
                type="text"
                name="name"
                value={profile.name}
                onChange={handleChange}
                required
              />
            </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group controlId="email" className="mb-3">
              <Form.Label>Email</Form.Label>
              <Form.Control
                className="bg-white"
                type="email"
                name="email"
                value={profile.email}
                onChange={handleChange}
                required
              />
            </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group controlId="canvaId" className="mb-3">
              <Form.Label>Canva Id</Form.Label>
              <Form.Control
                type="text"
                name="canvaId"
                value={profile.canvaId || "Account not connected to Canva"}
				disabled
              />
            </Form.Group>
            </Col>
            <Col md={6}>
            <Form.Group controlId="adobeId" className="mb-3">
              <Form.Label>Adobe Id</Form.Label>
              <Form.Control
                type="text"
                name="adobeId"
                value={profile.adobeId || "Account not connected to Adobe"}
				disabled
              />
            </Form.Group>
            </Col>
            </Row>
            <Row>
            <Col>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Updating...' : 'Update Profile'}
            </Button>
            </Col>
            </Row>
          </Form>
        )}
		</div>
		<div className="mt-5 mb-3">
        <h5>API Keys</h5>
        <p>Here's a list of all API Keys connected to your account.</p>
        {loadingApiKeys && <p>Loading API keys...</p>}
        {error && <Alert variant="danger">{error}</Alert>}
        <div className="table-responsive">
        <Table hover className="bg-white shadow-sm"  style={{whiteSpace: 'nowrap', overflow: 'hidden',textOverflow: 'ellipsis'}}>
          <thead>
            <tr>
              <th onClick={() => requestSort('createdAt')} style={{ cursor: 'pointer' }}>
                Created At {getSortIndicator('createdAt')}
              </th>
              <th onClick={() => requestSort('value')} style={{ cursor: 'pointer' }}>
                API Key {getSortIndicator('value')}
              </th>
              <th onClick={() => requestSort('tokens')} style={{ cursor: 'pointer' }} className="text-center">
                Tokens available {getSortIndicator('tokens')}
              </th>
            </tr>
          </thead>
          <tbody>
            {apiKeys.map((key) => (
              <tr key={key.id}>
                <td>{new Date(key.createdAt).toLocaleString()}</td>
                <td className="text-truncate">{key.value}</td>
                <td className="text-center">{key.tokens || 'No tokens'}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
